import { createSlice } from '@reduxjs/toolkit';

export const MAX_LEVEL = 4;

const initialState = {
	value: 1,
};

export const levelSlice = createSlice({
	name: 'level',
	initialState,
	reducers: {
		increment: (state) => {
			state.value = Math.min(state.value + 1, MAX_LEVEL);
		},
		decrement: (state) => {
			state.value = Math.max(state.value - 1, 1);
		},
		set: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { increment, decrement, set } = levelSlice.actions;

export const selectLevel = (state) => state.level.value;

export default levelSlice.reducer;
