import { useRef, useState } from 'react';
import { Html } from '@react-three/drei';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../firebase/firebase';
import { useStore } from '../../../../app/store';

const InteractiveElement = ({
	onHover,
	selected,
	title,
	userData,
	...props
}) => {
	const ref = useRef();
	const [hovered, setHovered] = useState(null);
	const [user] = useAuthState(auth);
	const { openScormModal, setScormURL, setLessonCompleted } = useStore();
	return (
		<mesh
			ref={ref}
			{...props}
			onPointerOver={(e) => {
				if (user) {
					onHover(ref);
					setHovered(true);
				}
			}}
			onPointerOut={(e) => {
				onHover(null);
				setHovered(false);
			}}
			onClick={async (e) => {
				if (user) {
					openScormModal();
					setScormURL(userData.module, userData.lesson);
					setLessonCompleted(userData.module, userData.lesson);
				}
			}}>
			<meshPhongMaterial color='#ff0000' opacity={0.01} transparent />
			{hovered && (
				<Html className='min-w-fit -right-[125px] -top-10 h-9'>
					<div className='px-4 py-2 text-center bg-white rounded-full shadow-xl text-slOrange'>
						<h1>{userData.name}</h1>
					</div>
				</Html>
			)}
		</mesh>
	);
};

export default InteractiveElement;
