import React, { useState, useEffect } from 'react';
import { logout } from '../firebase/firebase';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import { useStore } from '../app/store';

const SidebarProfile = ({ name }) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const [percentage, setPercentage] = useState(0);
	const progress = useStore((state) => state.progress);
	const { isCertificateModalOpen, openCertificateModal } = useStore();
	const length = progress.modules.reduce((x, y) => x + y.lessons.length, 0);
	const completed = progress.modules.reduce(
		(x, y) => x + y.lessons.filter((z) => z.finished).length,
		0
	);
	const percentageCalc = Math.round((completed / length) * 100);

	const localIsCertificateShown =
		JSON.parse(localStorage.getItem('isCertificateShown')) || false;

	useEffect(() => {
		setPercentage(percentageCalc);
		if (
			percentageCalc === 100 &&
			!isCertificateModalOpen &&
			!localIsCertificateShown
		) {
			openCertificateModal();
		}
	}, [completed]);
	return (
		<div className='flex items-center justify-end gap-3 text-right'>
			<div className='relative inline-block w-full text-left'>
				<div className='flex items-center justify-end gap-4'>
					<span className='self-start mr-auto'>
						<Progress
							width={40}
							type='circle'
							status={percentage === 100 ? 'success' : 'active'}
							theme={{
								active: {
									color: '#F2841C',
									trailColor: '#d6d6d6',
									trailWidth: 1,
									strokeWidth: 6,
									success: {
										color: '#F2841C',
										trailColor: '#000',
										trailWidth: 1,
										strokeWidth: 6,
									},
								},
								complete: {
									color: '#00bcd4',
									trailColor: '#d6d6d6',
									trailWidth: 1,
									strokeWidth: 6,
								},
								default: {
									color: '#F2841C',
									trailColor: '#d6d6d6',
									trailWidth: 1,
									strokeWidth: 6,
								},
							}}
							strokeWidth={4}
							percent={percentage}
						/>
					</span>

					<span className='text-sm'>Pozdrav, {name} </span>
					<button
						onClick={() => setShowDropdown(!showDropdown)}
						className='bg-[#6B7280] h-8 w-8 flex justify-center items-center rounded-full'>
						<img src='/icon-avatar.svg' alt='' />
					</button>
				</div>

				{showDropdown && (
					<div
						className='absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
						role='menu'
						aria-orientation='vertical'
						aria-labelledby='menu-button'
						tabIndex='-1'>
						<div className='py-1' role='none'>
							{/* <a
								href='#'
								className='text-gray-700 block px-4 py-2 text-sm border-b border-b-[#ECECEC]'
								role='menuitem'
								tabIndex='-1'
								id='menu-item-0'>
								Vaš profil
							</a> */}
							<button
								type='submit'
								className='block w-full px-4 py-2 text-sm text-left text-gray-700 transition-colors hover:bg-gray-200'
								role='menuitem'
								tabIndex='-1'
								onClick={logout}
								id='menu-item-3'>
								Odjavi se
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SidebarProfile;
