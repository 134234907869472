import React from 'react';

const Loading = () => {
	return (
		<div
			className='top-0 left-0 w-full h-screen bg-center bg-no-repeat bg-cover'
			style={{ backgroundImage: 'url("loadingImage.png")' }}></div>
	);
};

export default Loading;
