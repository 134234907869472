import React, { useEffect, useState } from 'react';
import './App.css';
import AuthModal from './components/AuthModal';
import LearnMoreModal from './components/LearnMoreModal';
import ScormModal from './components/ScormModal';
import CertificateModal from './components/CertificateModal';
import Copyright from './components/Copyright';
import Experience from './components/Experience/Experience';
import SocialShare from './components/SocialShare';
import Sidebar from './components/Sidebar';
import Spinner from './components/Spinner';

import { auth } from './firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { selectAuthModal, setAuthModal } from './features/authModalSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useStore } from './app/store';

function App() {
	// const [isAuthModalShown, setIsAuthModalShown] = useState(false);
	const [isLearnMoreModalShown, setIsLearnMoreModalShown] = useState(false);
	const localIsCertificateShown =
		JSON.parse(localStorage.getItem('isCertificateShown')) || false;
	const [isCertificateShown] = useState(localIsCertificateShown);
	const [user, loading] = useAuthState(auth);
	const isAuthModalOpen = useSelector(selectAuthModal);
	const dispatch = useDispatch();
	const { isScormModalOpen, closeCertificateModal, isCertificateModalOpen } =
		useStore();

	const handleStart = () => {
		if (!user) dispatch(setAuthModal(true));
	};

	const handleCloseCertificateModal = () => {
		closeCertificateModal();
	};

	return (
		<div className='landing'>
			<div className='fixed w-full h-screen'>
				{loading && <Spinner />}
				<Copyright />
				<Experience />
			</div>

			{isCertificateModalOpen && !isCertificateShown && (
				<CertificateModal closeDialog={() => handleCloseCertificateModal()} />
			)}
			{isAuthModalOpen && <AuthModal />}
			{isLearnMoreModalShown && (
				<LearnMoreModal closeDialog={() => setIsLearnMoreModalShown(false)} />
			)}
			{isScormModalOpen && <ScormModal />}
			{user && !loading && <Sidebar />}
			{!user && !loading && (
				<div className='z-10 relative mx-auto w-[526px] h-[601px] flex flex-col items-center'>
					<div className='z-20'>
						<p className='mt-48 text-2xl text-center title'>
							Kako prepoznati i spriječiti
						</p>
						<h1 className='text-6xl font-semibold text-[#2E2F39]'>
							Cyberbullying
						</h1>
						<div className='flex justify-center gap-5 mt-20 '>
							<button
								onClick={handleStart}
								className='bg-slOrange text-white w-[120px] h-[40px] rounded-[10px]'>
								Pristupi
							</button>
							<button
								className='bg-slOrange text-white w-[120px] h-[40px] rounded-[10px]'
								onClick={() => setIsLearnMoreModalShown(true)}>
								Saznaj više
							</button>
						</div>
						<SocialShare />
					</div>
					<svg
						className='absolute'
						width='526'
						height='601'
						viewBox='0 0 526 601'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M0 -112C0 -149.712 0 -168.569 11.7157 -180.284C23.4315 -192 42.2876 -192 80 -192H446C483.712 -192 502.569 -192 514.284 -180.284C526 -168.569 526 -149.712 526 -112V426.878C526 451.249 526 463.435 519.897 473.176C513.793 482.918 502.828 488.233 480.899 498.865L297.898 587.582C280.756 595.892 272.185 600.047 263 600.047C253.815 600.047 245.244 595.892 228.101 587.582L45.1015 498.865C23.1716 488.233 12.2066 482.918 6.10332 473.176C0 463.435 0 451.249 0 426.878V-112Z'
							fill='white'
							fillOpacity='0.9'
						/>
					</svg>
				</div>
			)}
		</div>
	);
}

export default App;
