import React, { useState } from 'react';
import CloseButton from './ModalCloseButton';

const AuthModal = ({ closeDialog }) => {
	return (
		<div
			id='learn-more-modal'
			className='fixed left-0 right-0 z-50 flex items-center justify-center h-full overflow-x-hidden overflow-y-auto bg-black/10 md:inset-0'>
			<div className=' relative px-4 w-full max-w-xl h-[540px]'>
				<div className='relative bg-white rounded-lg shadow'>
					<div className='flex justify-end p-2'>
						<CloseButton closeDialog={closeDialog} />
					</div>
					<div className='px-10 pb-12'>
						<h1 className='text-2xl '>Saznaj više o projektu Cyberbullying</h1>
						<p className='pr-2 text-sm mt-9'>
							Pored redovnih poslovnih obaveza koje imamo u našim svakodnevnim
							aktivnostima, odlučili smo pokrenuti inicijativu kreiranja
							besplatnih edukativnih kurseva koje želimo ustupiti široj javnosti
							na korištenje kako bismo našem društvu pomogli u procesu
							cjeloživotnog učenja i kontinuirane naobrazbe. <br />
							<br />
							Ovi kursevi za cilj imaju tretirati aktuelne teme koje igraju
							važnu ulogu u pogledu kolektivne odgovornosti i poziva na
							djelovanje. Jedan takav kurs je i kurs „Kako prepoznati i
							spriječiti cyberbullying?“. To je riječ i izraz koji su postali
							ustaljeni u našim rječnicima, nerijetko olako shvaćen, zanemaren,
							neuzet ozbiljno i tek kada prouzrokuje neželjene efekte vraćen u
							centar pažnje. Postoji razlog zašto baš želimo govoriti o ovoj
							temi, a to je utisak koji negativni trend neprikladnih komentara i
							sadržaja dostupnih na internetu ostavlja u toliko mjeri da djeluje
							kako postaje dio naše cyber kulture.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuthModal;
