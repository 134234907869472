import { configureStore } from '@reduxjs/toolkit';
import levelReducer from '../features/level/levelSlice';
import authModalReducer from '../features/authModalSlice';
import create from 'zustand';
import { progress, updateProgress } from '../firebase/firebase';
import { Timestamp } from '@firebase/firestore';

export const store = configureStore({
	reducer: {
		level: levelReducer,
		authModal: authModalReducer,
	},
});

export const useStore = create((set, get) => ({
	isScormModalOpen: false,
	isCertificateModalOpen: false,
	scormURL: '',
	progress: progress,
	scormData: [
		{
			name: 'Konflikt',
			module: 1,
			lesson: 1,
			geometry: 'nodes.Soba1_Knjiga1.geometry',
		},
		{
			name: 'Bullying',
			module: 1,
			lesson: 2,
		},
		{
			name: 'Cyberbullying',
			module: 1,
			lesson: 3,
		},
		{
			name: 'Porijeklo problema',
			module: 2,
			lesson: 1,
		},
		{
			name: 'Online okruženje',
			module: 2,
			lesson: 2,
		},
		{
			name: 'Ishodi izloženosti',
			module: 2,
			lesson: 3,
		},
		{
			name: 'Najpoznatiji primjeri',
			module: 2,
			lesson: 4,
		},
		{
			name: 'Žrtva cyberbullying-ay',
			module: 2,
			lesson: 5,
		},

		{
			name: 'Porodica',
			module: 3,
			lesson: 1,
		},
		{
			name: 'Odgojno - obrazovne ustanove',
			module: 3,
			lesson: 2,
		},
		{
			name: 'Prijatelji',
			module: 3,
			lesson: 3,
		},
		{
			name: 'Društvo',
			module: 3,
			lesson: 4,
		},
		{
			name: 'Samospoznaja',
			module: 3,
			lesson: 5,
		},
		{
			name: 'Primjer dobre prakse',
			module: 4,
			lesson: 1,
		},
		{
			name: 'Slovo zakona',
			module: 4,
			lesson: 2,
		},
		{
			name: 'Cyberbullying kao sociološki fenomen',
			module: 4,
			lesson: 3,
		},
	
		{
			name: 'Pravo',
			module: 4,
			lesson: 4,
		},
	],
	openScormModal: () => set((state) => ({ isScormModalOpen: true })),
	closeScormModal: () => set((state) => ({ isScormModalOpen: false })),
	openCertificateModal: () =>
		set((state) => ({ isCertificateModalOpen: true })),
	closeCertificateModal: () =>
		set((state) => ({ isCertificateModalOpen: false })),
	setScormURL: (module, lesson) =>
		set((state) => ({
			scormURL: `/Scorm/Module_${module}/Lekcija_${lesson}/story.html`,
		})),
	setProgress: (progress) => set((state) => ({ progress })),
	setLessonCompleted: async (modul, lekcija) => {
		set((state) => ({
			progress: {
				...state.progress,
				modules: state.progress.modules.map((module, index) =>
					index === modul - 1
						? {
								...module,
								lessons: module.lessons.map((lesson, index) =>
									index === lekcija - 1
										? {
												...lesson,
												started: true,
												finished: true,
										  }
										: lesson
								),
						  }
						: module
				),
			},
		}));
		updateProgress(get().progress);
	},
	setCourseCompleted: async () => {
		set((state) => ({
			progress: {
				...state.progress,
				completed: true,
				completedDate: Timestamp.fromDate(new Date()),
			},
		}));
		updateProgress(get().progress);
	},
}));
