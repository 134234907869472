import React, { useState } from 'react';
import { auth } from '../firebase/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { authErrors } from '../firebase/authErrors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ setAuthType, closeDialog }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const showToast = (message, type) => {
		toast(message, {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			type,
		});
	};

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			await signInWithEmailAndPassword(auth, email, password);
			showToast('Login successful', 'success');
			closeDialog();
		} catch (err) {
			showToast(authErrors[err.code], 'error');
		}
	};
	return (
		<form className='px-16 pb-12' onSubmit={handleLogin}>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<div className='flex flex-col items-center mt-5'>
				<svg
					width='67'
					height='68'
					viewBox='0 0 67 68'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M43.5429 16.9167C45.3552 16.9167 47.0932 17.6366 48.3747 18.9181C49.6562 20.1996 50.3762 21.9377 50.3762 23.75M64.0429 23.75C64.0438 26.952 63.2946 30.1097 61.8554 32.97C60.4163 35.8304 58.327 38.3138 55.7552 40.2213C53.1834 42.1289 50.2005 43.4074 47.0456 43.9545C43.8907 44.5016 40.6515 44.3021 37.5876 43.3719L29.8762 51.0833H23.0428V57.9167H16.2095V64.75H5.95951C5.05336 64.75 4.18431 64.39 3.54357 63.7493C2.90282 63.1085 2.54285 62.2395 2.54285 61.3333V52.4978C2.54304 51.5918 2.90314 50.7229 3.54393 50.0823L23.9209 29.7053C23.0683 26.8866 22.8329 23.9172 23.2309 20.9994C23.6289 18.0816 24.6508 15.2838 26.2272 12.7963C27.8035 10.3089 29.8972 8.19033 32.3659 6.58475C34.8345 4.97917 37.6201 3.92429 40.533 3.49193C43.446 3.05957 46.4179 3.25987 49.2464 4.07919C52.075 4.89851 54.6938 6.31762 56.9247 8.23993C59.1556 10.1622 60.9461 12.5426 62.1744 15.2191C63.4027 17.8955 64.04 20.8052 64.0429 23.75Z'
						stroke='#F2841C'
						strokeWidth='5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
				<h3 className='mt-5 mb-8 text-3xl font-medium text-center text-gray-900 '>
					Forma za prijavu
				</h3>
			</div>
			<div>
				<input
					type='email'
					name='email'
					id='email'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className='bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring- focus:ring-slOrange/30 focus:border-slOrange block w-full p-2.5 '
					placeholder='E-mail adresa'
					required
				/>
			</div>
			<div className='mt-2'>
				<input
					type='password'
					name='password'
					id='password'
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					placeholder='Šifra'
					className='bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring- focus:ring-slOrange/30 focus:border-slOrange block w-full p-2.5 '
					required
				/>
			</div>
			<div className='flex justify-between mt-4'>
				<div className='flex items-start'>
					<div className='flex items-center h-5 '>
						<input
							id='remember'
							type='checkbox'
							className='w-4 h-4 border rounded accent-slOrange bg-gray-50 border-slOrange/30 focus:ring-3 focus:ring-slOrange/30 '
						/>
					</div>
					<div className='ml-3 text-sm'>
						<label className='font-medium text-gray-900 '>Zapamti me</label>
					</div>
				</div>
				<a href='#' className='text-sm text-gray-900 hover:underline'>
					Zaboravili ste šifru?
				</a>
			</div>
			<div className='flex justify-between mt-8'>
				<button
					type='submit'
					className=' relative w-[200px] text-white bg-slOrange focus:ring-4 focus:ring-slOrange/20  rounded-lg text-sm px-5 py-2.5 text-center'>
					<svg
						width='14'
						height='16'
						viewBox='0 0 14 16'
						fill='none'
						className='absolute -translate-y-1/2 top-1/2'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M2 7V5C2 2.23858 4.23858 0 7 0C9.76142 0 12 2.23858 12 5V7C13.1046 7 14 7.89543 14 9V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V9C0 7.89543 0.895431 7 2 7ZM10 5V7H4V5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5Z'
							fill='white'
							fillOpacity='0.3'
						/>
					</svg>
					Prijava
				</button>
				<button
					onClick={(e) => {
						e.preventDefault();
						setAuthType('register');
					}}
					className=' w-[200px] text-white bg-slOrange focus:ring-4
								focus:ring-slOrange/20  rounded-lg text-sm px-5
								py-2.5 text-center relative'>
					<svg
						width='14'
						height='18'
						viewBox='0 0 14 18'
						fill='none'
						className='absolute -translate-y-1/2 top-1/2'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M9.35698 7.19036C9.9821 6.56523 10.3333 5.71739 10.3333 4.83333C10.3333 3.94928 9.9821 3.10143 9.35698 2.47631C8.73186 1.85119 7.88402 1.5 6.99996 1.5C6.1159 1.5 5.26806 1.85119 4.64294 2.47631C4.01782 3.10143 3.66663 3.94928 3.66663 4.83333C3.66663 5.71739 4.01782 6.56523 4.64294 7.19036C5.26806 7.81548 6.1159 8.16667 6.99996 8.16667C7.88402 8.16667 8.73186 7.81548 9.35698 7.19036Z'
							stroke='#F6A960'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M2.87517 12.3752C3.96913 11.2812 5.45286 10.6667 6.99996 10.6667C8.54706 10.6667 10.0308 11.2812 11.1248 12.3752C12.2187 13.4692 12.8333 14.9529 12.8333 16.5H1.16663C1.16663 14.9529 1.78121 13.4692 2.87517 12.3752Z'
							stroke='#F6A960'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
					Registracija
				</button>
			</div>
		</form>
	);
};

export default Login;
