import React from 'react';
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
} from 'react-share';

const SocialShare = () => {
	return (
		<div className='flex flex-col items-center mt-10'>
			<p className='text-sm'>Podijeli na društvenim mrežama</p>
			<div className='flex gap-2.5 mt-4'>
				<FacebookShareButton
					url={'https://cyberbullying.smartlab.ba'}
					quote={'Quote'}
					hashtag={'#hashtag'}
					description={'Description'}>
					<img src='icon-facebook.svg' alt='' />
				</FacebookShareButton>
				<TwitterShareButton
					title={'Title'}
					url={'https://cyberbullying.smartlab.ba'}
					hashtags={['hashtag1', 'hashtag2']}>
					<img src='icon-twitter.svg' alt='' />
				</TwitterShareButton>
				<LinkedinShareButton
					windowWidth={750}
					windowHeight={600}
					url={'https://cyberbullying.vercel.app/'}>
					<img src='icon-linkedin.svg' alt='' />
				</LinkedinShareButton>
			</div>
		</div>
	);
};

export default SocialShare;
