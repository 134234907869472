import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	value: false,
};

export const authModalSlice = createSlice({
	name: 'authModal',
	initialState,
	reducers: {
		setAuthModal: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setAuthModal } = authModalSlice.actions;

export const selectAuthModal = (state) => state.authModal.value;

export default authModalSlice.reducer;
