import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import Floor from './Floor';

export default function Model(props) {
	const group = useRef();
	const bakedTexture = useTexture('bakeFinal.png');
	const { nodes } = useGLTF('model.glb');
	return (
		<group ref={group} {...props} dispose={null}>
			<mesh
				geometry={nodes.model_soba.geometry}
				material={nodes.model_soba.material}
				position={[-2.72, -1.95, 1.51]}
				rotation={[0, -0.68, 0]}
				userData={{ name: 'model_soba' }}>
				<meshStandardMaterial
					map={bakedTexture}
					map-flipY={false}></meshStandardMaterial>
			</mesh>
			<Floor />
		</group>
	);
}

useGLTF.preload('model.glb');
