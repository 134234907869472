import React, { useEffect } from 'react';
import { AccordionPage } from './Accordion';
import SidebarProfile from './SidebarProfile';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase/firebase';
import { query, collection, getDocs, where } from 'firebase/firestore';
import { useStore } from '../app/store';
import { formatDate, generatePDF } from '../utils/utils';

const Sidebar = () => {
	const [user, loading] = useAuthState(auth);
	const { progress, setProgress } = useStore();
	const username = user?.displayName;

	const fetchUserData = async () => {
		const q = query(collection(db, 'users'), where('uid', '==', user?.uid));
		const doc = await getDocs(q);
		const data = doc.docs[0].data();
		setProgress(data.progress);
	};
	useEffect(() => {
		if (loading || !user.displayName) return;
		fetchUserData();
	}, [user, loading]);

	return (
		<div
			className='absolute right-0 top-0 z-40 w-80 bg-white/80 h-full backdrop-blur-[34px] px-5 py-10'
			style={{ boxShadow: '0px 4px 34px rgba(0,0,0,0.1)' }}>
			<div className='relative w-full h-full'>
				<SidebarProfile name={username} />
				<div className='mt-11'>
					{progress && <AccordionPage progress={progress} />}
				</div>
				{progress.completed && (
					<button
						aria-label='Preuzmi certifikat'
						className=' h-10 w-full absolute bottom-8 mt-7 mx-auto bg-slOrange text-white font-normal text-sm rounded-[10px] disabled:bg-gray-600/20'
						onClick={() =>
							generatePDF(username, formatDate(progress.completedDate))
						}>
						Preuzmi certifikat
					</button>
				)}
			</div>
		</div>
	);
};

export default Sidebar;
