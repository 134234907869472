import React, { useEffect } from 'react';
import CloseButton from './ModalCloseButton';
import { auth, db } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useStore } from '../app/store';
import { formatDate, generatePDF } from '../utils/utils';

const CertificateModal = ({ closeDialog }) => {
	const [user, loading] = useAuthState(auth);
	const username = user?.displayName;
	const { setCourseCompleted, progress } = useStore();

	const handleClose = () => {
		closeDialog();
	};
	useEffect(() => {
		setCourseCompleted();
		localStorage.setItem('isCertificateShown', JSON.stringify(true));
	}, []);

	return (
		<div
			id='authentication-modal'
			className='fixed left-0 right-0 z-50 flex items-center justify-center h-full overflow-x-hidden overflow-y-auto bg-black/10 md:inset-0'>
			<div className=' relative px-4 w-full max-w-xl h-[540px]'>
				<div className='relative bg-white rounded-lg shadow pb-9'>
					<div className='flex justify-end p-2'>
						<CloseButton closeDialog={handleClose} />
					</div>
					<div className='flex flex-col items-center justify-center px-9'>
						<img className='mx-auto mb-7' src='certificate-icon.svg'></img>
						<p className='text-sm font-bold text-center'>
							Uspješno ste završili interaktivni kurs “Kako prepoznati i
							spriječiti Cyberbullying”. Nadamo se da ste prepoznali vrijednost
							i značaj ove tematike u procesu cjeloživotnog učenja i
							kontinuirane naobrazbe.
						</p>
						<br />
						<p className='text-sm text-center'>
							U nastavku možete preuzeti digitalni certifikat kao rezultat Vašeg
							učešća. Zahvaljujemo na učestvovanju.
						</p>
						<button
							aria-label='Preuzmi certifikat'
							className=' h-10 px-14 mt-7 mx-auto bg-slOrange text-white font-normal text-sm rounded-[10px] disabled:bg-gray-600/20'
							onClick={() =>
								generatePDF(username, formatDate(progress.completedDate))
							}>
							Preuzmi certifikat
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CertificateModal;
