import React, { useState } from 'react';
import Login from './AuthModalLoginForm';
import Registration from './AuthModalRegistrationForm';
import CloseButton from './ModalCloseButton';
import { setAuthModal } from '../features/authModalSlice';
import { useDispatch } from 'react-redux';

const AuthModal = ({ closeDialog }) => {
	const dispatch = useDispatch();
	const [authType, setAuthType] = useState('login');

	const handleClose = () => {
		dispatch(setAuthModal(false));
	};

	return (
		<div
			id='authentication-modal'
			className='fixed left-0 right-0 z-50 flex items-center justify-center h-full overflow-x-hidden overflow-y-auto bg-black/10 md:inset-0'>
			<div className=' relative px-4 w-full max-w-xl h-[540px]'>
				<div className='relative bg-white rounded-lg shadow'>
					<div className='flex justify-end p-2'>
						<CloseButton closeDialog={handleClose} />
					</div>
					{authType === 'login' ? (
						<Login setAuthType={setAuthType} closeDialog={handleClose} />
					) : (
						<Registration setAuthType={setAuthType} closeDialog={handleClose} />
					)}
				</div>
			</div>
		</div>
	);
};

export default AuthModal;
