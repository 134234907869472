import React from 'react';

const Copyright = () => {
	return (
		<div className='absolute z-20 flex items-center gap-1 text-center -translate-x-1/2 bottom-3 left-1/2'>
			<span className='text-sm font-light text-white'>Developed by</span>
			<a href='https://smartlab.ba/' target='_blank'>
				<img src='copyright-logo.svg' alt='SmartLab' />
			</a>
		</div>
	);
};

export default Copyright;
