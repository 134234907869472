import React, { useEffect } from 'react';
import { selectLevel, MAX_LEVEL, set } from '../features/level/levelSlice';
import { selectAuthModal } from '../features/authModalSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useStore } from '../app/store';

export const AccordionPage = ({ progress }) => {
	const level = useSelector(selectLevel);
	const isAuthModalOpen = useSelector(selectAuthModal);
	const { openScormModal, setScormURL, setLessonCompleted } = useStore();

	return (
		<Accordion defaultPanel={`panel-${level - 1}`}>
			{progress.modules.map((module, moduleIndex) => {
				return (
					<div key={moduleIndex}>
						<AccordionItem
							key={moduleIndex}
							toggle={`panel-${moduleIndex}`}
							className='bg-white '>
							{module.name}
						</AccordionItem>
						<AccordionPanel id={`panel-${moduleIndex}`}>
							<div className='px-4 '>
								{module.lessons.map((lesson, lessonIndex) => {
									return (
										<div
											role={'button'}
											onClick={async (e) => {
												openScormModal();
												setScormURL(moduleIndex + 1, lessonIndex + 1);
												setLessonCompleted(moduleIndex + 1, lessonIndex + 1);
											}}
											key={lessonIndex}
											className='py-3 flex justify-between border-b border-b-[#C8D0E0] border-dashed last:border-b-0'>
											<p>{lesson.name}</p>
											{lesson.finished && <CheckedIcon />}
										</div>
									);
								})}
							</div>
						</AccordionPanel>
					</div>
				);
			})}
		</Accordion>
	);
};

const Context = React.createContext({});

function Accordion({ children, defaultPanel }) {
	const level = useSelector(selectLevel);
	const dispatch = useDispatch();
	const [selected, setSelected] = React.useState(defaultPanel || '');
	const toggleItem = React.useCallback(
		(id) => () => {
			setSelected((prevState) => (prevState !== id ? id : ''));
		},
		[]
	);
	useEffect(() => {
		dispatch(set(selected ? parseInt(selected.slice(-1)) + 1 : 0));
	}, [selected]);

	return (
		<Context.Provider value={{ selected, toggleItem }}>
			<>{children}</>
		</Context.Provider>
	);
}

const useAccordion = () => React.useContext(Context);

const style = {
	item: `flex justify-between items-center gap-2 focus:outline-none bg-white my-2 px-5 py-4 rounded-xl `,
	panel: `overflow-hidden md:overflow-x-hidden transition-height ease duration-300 text-gray-600`,
	active: `bg-[#6B7280] text-white`,
};

function AccordionItem({ toggle, children, className }) {
	const { selected, toggleItem } = useAccordion();
	return (
		<div
			role='button'
			onClick={toggleItem(toggle)}
			className={`${style.item} ${className} ${
				selected === toggle ? style.active : ''
			}`}
			style={{ boxShadow: '0px 1px 4px rgba(142, 142, 142, 0.4)' }}>
			{children}
			<span
				className={`float-right ${selected === toggle ? 'text-white' : ''}`}>
				{selected === toggle ? <AngleUpIcon /> : <AngleDownIcon />}
			</span>
		</div>
	);
}

function AccordionPanel({ children, id }) {
	const level = useSelector(selectLevel);
	const { selected } = useAccordion();
	const ref = React.useRef();
	const inlineStyle =
		selected === id ? { height: ref.current?.scrollHeight } : { height: 0 };

	return (
		<div ref={ref} id={id} className={style.panel} style={inlineStyle}>
			{children}
		</div>
	);
}

const AngleUpIcon = () => (
	<svg
		fill='currentColor'
		strokeWidth='0'
		viewBox='0 0 320 512'
		xmlns='http://www.w3.org/2000/svg'
		className='h-4 mt-1'>
		<path d='M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z' />
	</svg>
);

const AngleDownIcon = () => (
	<svg
		fill='currentColor'
		strokeWidth='0'
		viewBox='0 0 320 512'
		xmlns='http://www.w3.org/2000/svg'
		className='h-4 mt-1'>
		<path d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z' />
	</svg>
);

const CheckedIcon = () => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M7 10L9 12L13 8M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z'
			stroke='#F2841C'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
