import { initializeApp } from 'firebase/app';
import {
	getAuth,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
	updateProfile,
} from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const progress = {
	modules: [
		{
			name: 'Osnovne informacije i identifikacija',
			lessons: [
				{
					name: 'Konflikt',
					started: false,
					finished: false,
				},
				{
					name: 'Bullying',
					started: false,
					finished: false,
				},
				{
					name: 'Cyberbullying',
					started: false,
					finished: false,
				},
			],
		},
		{
			name: 'Uzroci, posljedice i primjeri',
			lessons: [
				{
					name: 'Porijeklo problema',
					started: false,
					finished: false,
				},
				{
					name: 'Online okruženje',
					started: false,
					finished: false,
				},
				{
					name: 'Ishodi izloženosti',
					started: false,
					finished: false,
				},
				{
					name: 'Najpoznatiji primjeri',
					started: false,
					finished: false,
				},
				/*
				{
					name: 'Žrtva cyberbullying-a',
					started: true,
					finished: true,
				},
				*/
			],
		},
		{
			name: 'Ko može pomoći i kakvu ko ulogu ima?',
			lessons: [
				{
					name: 'Porodica',
					started: false,
					finished: false,
				},
				{
					name: 'Odgojno-obrazovne ustanove',
					started: false,
					finished: false,
				},
				{
					name: 'Prijatelji',
					started: false,
					finished: false,
				},
				{
					name: 'Društvo',
					started: false,
					finished: false,
				},
				{
					name: 'Samospoznaja',
					started: false,
					finished: false,
				},
			],
		},
		{
			name: 'Riječ stručnjaka',
			lessons: [
				{
					name: 'Primjer dobre prakse',
					started: false,
					finished: false,
				},
				{
					name: 'Slovo zakona',
					started: false,
					finished: false,
				},
				{
					name: 'Cyberbullying kao sociološki fenomen',
					started: false,
					finished: false,
				},
				/*
				{
					name: 'Pravo',
					started: true,
					finished: true,
				},
				*/
			],
		},
	],
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

const registerWithEmailAndPassword = async (name, email, password) => {
	try {
		const res = await createUserWithEmailAndPassword(auth, email, password);
		await updateProfile(auth.currentUser, { displayName: name }).catch((err) =>
			console.log(err)
		);
		const user = res.user;
		await setDoc(doc(db, 'users', user.uid), {
			uid: user.uid,
			name,
			authProvider: 'local',
			email,
			progress,
			dateOfCompletion: null,
		});
	} catch (err) {
		console.error('Lala', err);
	}
};

const updateProgress = async (progress) => {
	const user = auth.currentUser;
	const uid = user.uid;
	await setDoc(doc(db, 'users', uid), {
		uid,
		name: user.displayName,
		authProvider: 'local',
		email: user.email,
		progress,
		dateOfCompletion: null,
	});
};
/* const updateDateOfCompletion = async (progress) => {
	const user = auth.currentUser;
	const uid = user.uid;
	await setDoc(doc(db, 'users', uid), {
		uid,
		name: user.displayName,
		authProvider: 'local',
		email: user.email,
		progress,
		dateOfCompletion: new Date(),
	});
}; */

const sendPasswordReset = async (email) => {
	try {
		await sendPasswordResetEmail(auth, email);
		alert('Password reset link sent!');
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const logout = () => {
	signOut(auth);
};

export {
	auth,
	analytics,
	db,
	registerWithEmailAndPassword,
	sendPasswordReset,
	updateProgress,
	// updateDateOfCompletion,
	logout,
	progress,
};
