import React, { useState } from 'react';
import { registerWithEmailAndPassword } from '../firebase/firebase';
import { authErrors } from '../firebase/authErrors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Registration = ({ setAuthType, closeDialog }) => {
	const [email, setEmail] = useState('');
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassowrd, setConfirmPassword] = useState('');

	const showToast = (message, type) => {
		toast(message, {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			type,
		});
	};

	const handleRegister = async (e) => {
		e.preventDefault();
		if (password !== confirmPassowrd) {
			showToast('Passwords do not match', 'error');
			return;
		}
		try {
			await registerWithEmailAndPassword(username, email, password);
			closeDialog();
		} catch (err) {
			showToast(authErrors[err.code], 'error');
		}
	};

	return (
		<form className='px-16 pb-2' onSubmit={handleRegister}>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<div className='flex flex-col items-center mt-5'>
				<h3 className='mt-5 mb-12 text-3xl font-medium text-center text-gray-900 '>
					Registracija
				</h3>
			</div>
			<div>
				<input
					type='text'
					name='username'
					id='username'
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					className='bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring- focus:ring-slOrange/30 focus:border-slOrange block w-full p-2.5 '
					placeholder='Ime i prezime'
					required
				/>
			</div>
			<div className='mt-2'>
				<input
					type='email'
					name='email'
					id='email'
					value={email}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					className='bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring- focus:ring-slOrange/30 focus:border-slOrange block w-full p-2.5 '
					placeholder='E-mail adresa'
					required
				/>
			</div>
			<div className='mt-2'>
				<input
					type='password'
					name='password'
					id='password'
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					placeholder='●●●●●●●●'
					className='bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring- focus:ring-slOrange/30 focus:border-slOrange block w-full p-2.5 '
					required
				/>
			</div>
			<div className='mt-2'>
				<input
					type='password'
					name='confirmPassword'
					id='confirmPassword'
					value={confirmPassowrd}
					onChange={(e) => setConfirmPassword(e.target.value)}
					placeholder='●●●●●●●●'
					className='bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring- focus:ring-slOrange/30 focus:border-slOrange block w-full p-2.5 '
					required
				/>
			</div>

			<div className='flex justify-center mt-8'>
				<button
					type='submit'
					className=' relative w-[200px] text-white bg-slOrange focus:ring-4 focus:ring-slOrange/20  rounded-lg text-sm px-5 py-2.5 text-center'>
					Završi registraciju
				</button>
			</div>
			<div className='mt-8 text-sm font-medium text-center text-gray-500'>
				<button
					onClick={(e) => {
						e.preventDefault();
						setAuthType('login');
					}}
					className='text-gray-500 hover:underline'>
					Već ste registrovani
				</button>
			</div>
		</form>
	);
};

export default Registration;
