import React, { useRef, useState } from 'react';
import * as THREE from 'three';
import { EffectComposer, Outline } from '@react-three/postprocessing';
import InteractiveElement from './Outline/InteractiveElement';
import Model from './Model';
import { useGLTF, Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase/firebase';
import { useStore } from '../../../app/store';

const World = ({ level }) => {
	const ref = useRef();
	const [hovered, onHover] = useState(null);
	const selected = hovered ? [hovered] : undefined;
	const { nodes } = useGLTF('model.glb');
	const [user] = useAuthState(auth);
	const { scormData } = useStore();

	const positions = [
		[-5, 0, -2], // initial
		[3.7, 0, -3.3], // level 1
		[1.5, -0.7, 1.4], // level 2
		[-1.2, -1.8, 4.3], //level 3
		[-6, 0.3, 3.3], // level 4
	];

	const damp = (target, to, step, delta, v = new THREE.Vector3()) => {
		if (target instanceof THREE.Vector3) {
			target.x = THREE.MathUtils.damp(target.x, to[0], step, delta);
			target.y = THREE.MathUtils.damp(target.y, to[1], step, delta);
			target.z = THREE.MathUtils.damp(target.z, to[2], step, delta);
		}
	};

	const adjustCamera = (state, delta) => {
		damp(ref.current.position, positions[level], 3, delta);
		state.camera.fov = THREE.MathUtils.damp(
			state.camera.fov,
			level ? 10 : 35,
			4,
			delta
		);
		state.camera.updateProjectionMatrix();
	};

	useFrame((state, delta) => {
		user && adjustCamera(state, delta);
	});

	return (
		<group>
			<EffectComposer multisampling={8} autoClear={false}>
				<Outline
					blur
					selection={selected}
					visibleEdgeColor='white'
					edgeStrength={10}
					width={500}
				/>
			</EffectComposer>
			<group
				ref={ref}
				dispose={null}
				scale={[0.4, 0.4, 0.4]}
				rotation={[0, Math.PI, 0]}>
				<Model />
				<Text
					ref={ref}
					color={'#fff'}
					fontSize={0.4}
					lineHeight={1}
					letterSpacing={0.02}
					maxWidth={10}
					textAlign={'left'}
					font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
					anchorX='center'
					anchorY='middle'
					rotation={[0, Math.PI, 0]}
					position={[-14.5, 0.75, 13.34]}>
					04 RIJEČ STRUČNJAKA
				</Text>
				<Text
					ref={ref}
					color={'#fff'}
					fontSize={0.4}
					lineHeight={1}
					maxWidth={6}
					letterSpacing={0.02}
					textAlign={'left'}
					font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
					anchorX='center'
					anchorY='middle'
					rotation={[0, Math.PI, 0]}
					position={[-3, 6.5, 14.2]}>
					03 KO MOŽE POMOĆI I KAKVU ULOGU IMA
				</Text>
				<Text
					ref={ref}
					color={'#fff'}
					fontSize={0.4}
					lineHeight={1}
					maxWidth={6}
					letterSpacing={0.02}
					textAlign={'left'}
					font='1Ptrg8zYS_SKggPNwK4vaqI.woff'
					anchorX='center'
					anchorY='middle'
					rotation={[0, Math.PI, 0]}
					position={[4, 4, 5]}>
					02 UZROCI, POSLJEDICE I PRIMJERI
				</Text>
				<Text
					ref={ref}
					color={'#fff'}
					fontSize={0.4}
					lineHeight={1}
					maxWidth={4}
					letterSpacing={0.02}
					textAlign={'left'}
					font='/1Ptrg8zYS_SKggPNwK4vaqI.woff'
					anchorX='center'
					anchorY='middle'
					rotation={[0, Math.PI, 0]}
					position={[11.5, 1.25, -5.5]}>
					01 OSNOVNE INFORMACIJE I IDENTIFIKACIJA
				</Text>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba1_Knjiga1.geometry}
					position={[13.69, -0.01, -9.54]}
					rotation={[0, -1.06, 0]}
					userData={scormData[0]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba1_knjiga2.geometry}
					position={[10.84, -0.9, -9.94]}
					hp={150}
					rotation={[3.13, 0.22, 2.83]}
					userData={scormData[1]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba1_laptop.geometry}
					position={[11.15, -0.61, -7.72]}
					rotation={[0, 0.31, -Math.PI / 2]}
					userData={scormData[2]}
				/>

				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba2_Gitara.geometry}
					position={[5.09, 0.55, 5]}
					rotation={[-1.52, 0.22, -0.01]}
					userData={scormData[3]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba2_mobitel.geometry}
					position={[5.63, 0.49, 3.99]}
					rotation={[0, 0.68, 0]}
					userData={scormData[4]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba2_lopta.geometry}
					position={[1.86, 0.09, 1.26]}
					rotation={[0, 1, 0]}
					userData={scormData[5]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba2_racunar.geometry}
					position={[2.58, 2, 4.58]}
					rotation={[-Math.PI, 0.01, -Math.PI]}
					userData={scormData[6]}
				/>
				{
				/*
					<InteractiveElement
						onHover={onHover}
						selected={selected}
						geometry={nodes.Soba2_Stolica.geometry}
						position={[2.1, 0.63, 3.99]}
						rotation={[-Math.PI, 0.01, -Math.PI]}
						userData={scormData[7]}
					/>
				*/
				}
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba3_tv.geometry}
					position={[0.49, 4.85, 11.06]}
					rotation={[-Math.PI / 2, 1.52, Math.PI / 2]}
					userData={scormData[8]}
				/>

				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba3_slika.geometry}
					position={[0.51, 4.27, 12.39]}
					rotation={[-Math.PI, Math.PI / 2, 0]}
					userData={scormData[9]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba3_Lampa.geometry}
					position={[-1.02, 3.13, 6.4]}
					userData={scormData[10]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba3_stolica.geometry}
					position={[-2.61, 3.61, 7.98]}
					rotation={[-Math.PI, -0.04, -Math.PI]}
					userData={scormData[11]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba3_laptop.geometry}
					position={[-3.06, 3.6, 9.69]}
					rotation={[0, 1.55, 0]}
					userData={scormData[12]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba4_Fotelja.geometry}
					position={[-13.81, -1.84, 9.5]}
					rotation={[0, Math.PI / 2, 0]}
					userData={scormData[13]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba4_racunar.geometry}
					position={[-11.5, -1.1, 7.54]}
					rotation={[0, 0.71, 0]}
					userData={scormData[14]}
				/>
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba4_kamera.geometry}
					position={[-12.3, -0.59, 7.34]}
					rotation={[-2.41, 1.03, 2.49]}
					userData={scormData[15]}
				/>
				{
				/*
				<InteractiveElement
					onHover={onHover}
					selected={selected}
					geometry={nodes.Soba4_Papiri.geometry}
					position={[-12.28, -1.1, 5.09]}
					rotation={[0, 0.27, 0]}
					userData={scormData[16]}
				/>
				*/
				}
			</group>
		</group>
	);
};

useGLTF.preload('model.glb');

export default World;
