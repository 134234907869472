import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';
import NotSupported from './components/NotSupported';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserView>
				<App />
			</BrowserView>
			<MobileView>
				<NotSupported />
			</MobileView>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
