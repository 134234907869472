import { Canvas } from '@react-three/fiber';
import { PresentationControls } from '@react-three/drei';
import World from './World/World';
import { useSelector } from 'react-redux';
import { selectLevel } from '../../features/level/levelSlice';
import { useEffect, Suspense } from 'react';
import Loading from './Loading';

export default function Experience() {
	const level = useSelector(selectLevel);

	useEffect(() => {}, []);

	return (
		<Suspense fallback={<Loading />}>
			<Canvas flat dpr={[1, 2]} camera={{ fov: 35, position: [4, 4, 16] }}>
				<color attach='background' args={['#182032']} />
				<ambientLight intensity={1} />
				<directionalLight intensity={0.5} position={[-1, 1, 1]} />
				{/* <fog attach='fog' args={['#fff', 30, 40]} /> */}
				<PresentationControls
					global
					rotation={[0, -Math.PI / 5, 0]}
					polar={[0, Math.PI / 4]}
					azimuth={[-Math.PI / 4, Math.PI / 4]}>
					<World level={level} />
				</PresentationControls>
			</Canvas>
		</Suspense>
	);
}
