import React from 'react';
import CloseButton from './ModalCloseButton';
import { useStore } from '../app/store';

const ScormModal = ({ closeDialog }) => {
	const { closeScormModal, scormURL, setScormURL } = useStore();
	const handleClose = () => {
		closeScormModal();
		setScormURL('');
	};
	return (
		<div
			id='scorm-modal'
			className='fixed inset-0 z-50 flex items-center justify-center h-screen p-12 overflow-x-hidden overflow-y-auto bg-black/10 backdrop-blur-lg md:inset-0'>
			<div className='relative flex items-center h-full px-4'>
					<div className='z-10 mt-[-55%] bg-white rounded-full absolute right-0 flex p-2'>
						<CloseButton closeDialog={handleClose} />
					</div>
				<div className='border-[6px] border-white relative h-[720px] overflow-hidden bg-white rounded-lg shadow w-[1280px]'>
					<iframe
						title='Scorm'
						className='w-full h-full aspect-ratio-16/9'
						src={scormURL}
						frameBorder='0'></iframe>
				</div>
			</div>
		</div>
	);
};

export default ScormModal;
