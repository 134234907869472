import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';

export default function Model(props) {
	const group = useRef();
	const { nodes, materials } = useGLTF('/Floor.glb');
	const bakedTexture = useTexture('/flooor_optim.jpg');
	const alphaTexture = useTexture('/flooor_optimAlpha.jpg');

	return (
		<group ref={group} {...props} dispose={null}>
			<mesh
				geometry={nodes.FLOOOOOR.geometry}
				position={[2.2, 0.313, 0]}
				scale={[57.51, 20.52, 44.12]}>
				<meshStandardMaterial
					map={bakedTexture}
					transparent='true'
					map-flipY={false}
					alphaMap={alphaTexture}
				/>
			</mesh>
		</group>
	);
}

useGLTF.preload('/Floor.glb');
