import jsPDF from 'jspdf';

export const formatDate = (date) => {
	const d = date.toDate();
	var dd = d.getDate();
	var mm = d.getMonth() + 1;
	var yyyy = d.getFullYear();
	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}
	const formattedDate = `${dd}.${mm}.${yyyy}.`;
	return formattedDate;
};

export const generatePDF = (username, date) => {
	const doc = new jsPDF('l', 'mm', [217, 150]);
	var img = new Image();
	img.src = 'certificate.png';
	doc.addImage(img, 'JPEG', 0, 0, 217, 150);

	doc.addFont('fonts/Montserrat-Regular.ttf', 'Montserrat-Regular', 'normal');
	doc.addFont('fonts/Montserrat-Medium.ttf', 'Montserrat-Medium', 'normal');
	doc.addFont('fonts/Montserrat-SemiBold.ttf', 'Montserrat-SemiBold', 'normal');
	doc.addFont('fonts/Montserrat-Bold.ttf', 'Montserrat-Bold', 'normal');
	doc.setFont('Montserrat-Bold');
	doc.setFontSize(54);
	doc.setTextColor(46, 47, 62);
	doc.text('CERTIFIKAT', 108, 53, { align: 'center' });

	doc.setFont('Montserrat-SemiBold');
	doc.setFontSize(30);
	doc.setTextColor(248, 150, 36);
	doc.text(username.toUpperCase(), 108, 75, { align: 'center' });

	doc.setFont('Montserrat-Regular');
	doc.setFontSize(14);
	doc.setTextColor(248, 150, 36);
	doc.text('Kako prepoznati i spriječiti "Cyberbullying"', 108, 108, {
		align: 'center',
	});

	doc.setFont('Montserrat-Medium');
	doc.setFontSize(11);
	doc.setTextColor(129, 146, 163);
	doc.text('Za uspješno finaliziranje kursa', 108, 99, {
		align: 'center',
	});

	doc.setFontSize(9);
	doc.setTextColor(53, 75, 91);
	doc.text('DATUM', 109.375, 138, {
		align: 'center',
	});
	doc.text(date, 109.375, 131, {
		align: 'center',
	});

	doc.save(`Cyberbullying Certifikat - ${username}.pdf`);
};
